<template>
  <div class="assessment-builder builder">
    <component :is="currentSection" />
  </div>
</template>
<script>
import BuilderMixin from "@/views/BackOffice/BuilderMixin.js";
import SkillLessonBuilderContentSection from "@/components/BackOffice/SkillLessonBuilder/SkillLessonBuilderContentSection.vue";
import SkillLessonBuilderLayoutSection from "@/components/BackOffice/SkillLessonBuilder/SkillLessonBuilderLayoutSection.vue";

export default {
  name: "SkillLessonBuilder",
  mixins: [BuilderMixin],
  components: {
    "layout-section": SkillLessonBuilderLayoutSection,
    "content-section": SkillLessonBuilderContentSection,
  },
  created() {
    this.isLoadingInformation = true;
    this.$store.commit("loadLessonBuilderComponents", null);
    this.$store
      .dispatch(
        "loadSkillLessonBuilderComponents",
        parseInt(this.$route.params.lessonId)
      )
      .finally(() => (this.isLoadingInformation = false));
    this.$store.commit("updateSwitchButtonDisplayStatus", true);
  },
};
</script>
<style lang="less" scoped>
</style>
