<template>
	<div class="content-component row" @click="updateCurrentComponent" :style="{order : component.order}" :class="[component.paragraph.type, {'component-active': isActive}]">
       <span class="legend centered">{{$t(component.paragraph.text)}}</span>
    <span class="content-component-count">{{ componentCount }}</span>
     <button class="btn secondary delete"  @click="deleteComponent(component)">{{$t('delete')}}</button>
         <input class="title" type="text" v-model="title" :placeholder="$t('titlePlaceHolder')">
	</div>
</template>

<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
export default {
  name: 'AssessmentGroupStartComponent',
  props:{
       componentCount: Number,
  },
   mixins: [BackOfficeComponentMixin],
  data() {
    return {}
  },
  methods: {
    setBuilderComponentPropertyValue(value, propertyName = this.options.model, language = null) {
            this.$store.commit('setBuilderComponentPropertyValue',
                { 'component': this.component.paragraph, 'propertyName': propertyName, 'value': value, language: language })
        }
  },
  computed: {
    title:{
       get(){
                return this.component.paragraph.title[this.locale]
            }, 
            set(value)
            {
                this.setBuilderComponentPropertyValue(value, 'title', this.locale)
            }
    }
  },
  created() {

  },
  mounted() {

  },
  updated() {

  },
  destroyed() {

  },
  watch: {}
}
</script>

<style lang='less' scoped>
.content-component {
        background-color: #f6f7fa !important;
        &.component-active {
            background-color: #fff !important;
        }
        &.section-title {
            .legend{
                font-size: 19.64px;
                color: #0a1554;
                font-weight: bold;
            }
        }
}
</style>