<template>
  <div class="lesson-component-layout" :class="componentData.type">
    <div class="flex-container">
      <!-- <select
        name="assessment-group"
        v-model="assessmentGroupId"
        id="assessment-group"
        style="width: 88px"
        @change="addComponentToGroup($event)"
      >
        <option value="0">No AG</option>
        <option
          v-for="(group, index) in assessmentGroupArray"
          :value="group.id"
          :key="index"
        >
          {{ group.id }}
        </option>
      </select> -->
      <!-- <div class="test ml-2" style="display: flex; gap: 6px">
        <button type="button" @click="createAssessmentGroup()">Add Assessment Group</button>
        <button class="btn-add_ip">Add IP</button>
        <button class="btn-add_gp">Add GP</button>
      </div> -->
    </div>
    <div class="flex-container" style="height:100%;width:100%">
      <span class="component-count">{{ componentCount }}</span>
      <div
        class="image-placeholder"
        :class="[componentData.imagesOrientation]"
      ></div>
      <section class="content-section flex-container">
        <div class="title-placeholder"></div>
        <div class="text-placeholder"></div>
      </section>
      <span class="legend centered">{{ $t(componentData.text) }}</span>
      <div class="component-controls flex-container">
        <img
          :src="arrowUpIcon"
          :style="{ opacity: arrowUpDisabledStyle }"
          @click="changeComponentOrder(component.order - 1)"
        />
        <img
          :src="arrowDownIcon"
          :style="{ opacity: arrowDownDisabledStyle }"
          @click="changeComponentOrder(component.order + 1)"
        />
        <span class="" @click="deleteComponent(component)">{{
          $t("delete")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SkillLessonBuilderLayoutComponent",
  props: {
    component: Object,
    componentCount: Number,
  },
  data() {
    return {
      assessmentGroups: [],
      assessmentGroupId: this.component.assessmentGroupNumber,
    };
  },
  computed: {
    componentData() {
      return this.component && this.component.paragraph
        ? this.component.paragraph
        : this.component.question
        ? this.component.question
        : this.component.assessmentGroup;
    },
    arrowUpIcon() {
      return require("@/assets/icons/arrow_upward.svg");
    },
    arrowDownIcon() {
      return require("@/assets/icons/arrow_downward.svg");
    },
    arrowUpDisabledStyle() {
      return this.component.order === 1 ? "0.3" : "1";
    },
    arrowDownDisabledStyle() {
      return this.component.order === this.components.length ? "0.3" : "1";
    },
    components() {
      return this.$store.state.LessonBuilderComponents;
    },
    assessmentGroupArray() {
      return this.$store.state.assessmentGroupArray;
    },
  },
  methods: {
    onChange({ added }) {
      if (added)
        this.$store.commit("loadComponentToAssessmentGroup", added.element);
      // if (moved){
      //   //  console.log(moved);
      // }
      
    },
    changeComponentOrder(newOrder) {
      if (newOrder === 0 || newOrder > this.components.length) return;
      this.$store.commit("changeLessonBuilderComponentOrder", {
        component: this.component,
        newOrder: newOrder,
      });

      this.$store.commit("updateLessonBuilderComponentOrder");
      this.$store.dispatch("reoderLessonComponent", this.component);
    },
    deleteComponent() {
      this.$store.commit("updateLoadingStatus", true);
      setTimeout(() => {
        this.$store
          .dispatch("removeLessonBuilderComponent", this.component)
          .then(() => {
            this.$store.commit("deleteLessonBuilderComponent", this.component);
            this.$store.commit("updateCurrentBackOfficeComponent", undefined);
            this.$store.commit("updateLoadingStatus", false);
          });
      }, 500);
    },
    // getComponentPartialOrder(questionType, overallOrder) {
    //   let firstComponent = this.components.find(
    //     (elem) => elem.type == questionType
    //   );

    //   return firstComponent && firstComponent.order < overallOrder
    //     ? overallOrder - firstComponent.order + 1
    //     : 1;
    // },
    // createAssessmentGroup() {
    //   // arr[arr.length]+1;
    //   var id = this.$store.state.assessmentGroupArray.length + 1;
    //   let payload = {
    //     lessonId: this.$route.params.lessonId,
    //     id: id,
    //   };
    //   //Need to add api call for create new assessmentGroup
    //   this.$store.commit("createAssessmentGroup", payload);
    // },

    // addComponentToGroup(e) {
    //   let groupId = e.target.value;
    //   let payload = {
    //     lessonId: this.$route.params.lessonId,
    //     assessmentGroupNumber: parseInt(groupId),
    //     componentId: this.component.id,
    //   };

    //   //Need to add api call for add component to assessmentGroup
    //   this.$store.dispatch('assignComponentToAssessmentGroup', payload)
    
    // }
  },
};
</script>
<style lang="less" scoped>
.lesson-component-layout {
  height: 136px;
  min-height: 136px;
  min-width: 704px;
  width: 704px;
  background-color: #eaebff;
  box-shadow: var(--primary-shadow);
  cursor: pointer;
  position: relative;
  margin: 16px auto !important;
  z-index: 1;
  .component-count {
    margin-top: 5px;
    margin-left: 10px;
    font-weight: bold;
    position: absolute;
  }
  div:not(.component-controls) {
    background-color: rgba(58, 77, 253, 0.1);
  }
  .component-controls.flex-container {
    width: 147px;
    align-content: center;
    span {
      margin-left: auto;
    }
    img {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      align-self: center;
      cursor: pointer;
    }
  }

  & /deep/ .drag-drop-elements {
    // height: calc(100vh - 166px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.video-content {
  max-height: 136px;
  // max-width: 549px;
  min-width: 549px;

  .image-placeholder {
    position: absolute;
    left: 50%;
    min-height: 91px;
    min-width: 349px;
    transform: translate(-50%);
  }
}

.assessment-group {
  min-height: 350px !important;
  .legend {
    position: absolute;
    top: 25px;
  }
}

.content-section {
  flex-direction: column;
  margin-left: 20px;
}
.section-title,
.only-images {
  min-height: 64px;
  height: 64px;
  .content-section{
    display: none !important;
  }
  .image-placeholder {
    display: none !important;
  }
  .legend {
    font-size: 19.64px;
    color: #0a1554;
    font-weight: bold;
  }
}

.group-header{
  min-height: 60px;
  height: 60px;
  .image-placeholder{
    display: none;
  }
  .title-placeholder{
    display: none;
  }
  .text-placeholder{
    background-color: rgba(58, 77, 253, 0.2) !important ;
    margin-top: 0px;
    height: 60px;
    margin-left: 182px;
    width: 300px;
    max-width: 300px;
  }
}

.paragraph,
.video-content,
.interactive-lesson,
.interactive-map,
.single-image {
  .content-section {
    display: none;
  }
}

.written,
.essay{
  .image-placeholder{
    display: none;
  }
  .title-placeholder{
    height: 32px;
    margin-top: 16px;
    width: 650px;
    max-width: 650px;
    margin-left: 4px;
  }
  .text-placeholder{
    margin-top: 16px;
    width: 624px;
    max-width: 650px;
    margin-inline: auto;
  }
}

.multiple-choice{
  height: 136px;
  background-image: url('../../../assets/MCQ.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: 604px;
  .image-placeholder{
    display: none;
  }
  .title-placeholder{
    display: none;
  }
  .text-placeholder{
    display: none;
  }
}

.venn-diagram > .flex-container{
  display: flex;
  flex-direction: column;
}
.venn-diagram{
  height: 136px;
  min-height: 136px;
  background-image: url("../../../assets/venn.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 223px;
  .image-placeholder{
    display: none;
  }
  .title-placeholder{
    display: none;
  }
  .text-placeholder{
    display: none;
  }
}
.interactive-map {
  .content-section,
  .image-placeholder {
    display: none;
  }
}

.single-image .image-placeholder {
  min-width: 500px;
  margin: 22px auto;
}

.left-side {
  order: 0;
  margin-right: unset;
}
.right-side {
  order: 1;
  margin-left: auto;
  margin-right: 24px;
}
.text-content {
  display: flex !important;
}
.btn-add_ip {
  background-color: #4778A9;
  border-radius: 16px;
  color: white;
  padding-inline: 8px;
}
.btn-add_gp {
  background-color: #3F6B97;
  border-radius: 16px;
  color: white;
  padding-inline: 8px;
}
</style>
