<template>
  <div class="layout-section flex-container" v-cloak>
    <div class="components-container" v-cloak>
      <div class="lesson-banner-component flex-container">
        <span>{{ $t("skillLessonBanner") }}</span>
      </div>
      <draggable
        class="draggable-section"
        v-model="components"
        :options="dragOptions"
        :move="onMove"
        @change="onChange"
      >
        <transition-group name="flip-list" tag="ul">
          <li v-for="(component, index) in components" :key="component.id">
            <layout-component
              :component="component"
              :componentCount="index + 1"
            />
          </li>
        </transition-group>
      </draggable>
    </div>
    <skill-lesson-catalog />
  </div>
</template>
<script>
import SkillLessonCatalog from "@/components/BackOffice/LayoutComponents/SkillLessonCatalog.vue";
import SkillLessonBuilderLayoutComponent from "./SkillLessonBuilderLayoutComponent.vue";
import questionTemplates from '@/assets/BackOfficeAssets/question-templates.json'
import LayoutSectionMixin from "@/components/BackOffice/Mixins/LayoutSectionMixin.js";
import Factory from "@/utils/ContentFactory.js";
export default {
  name: "SkillLessonBuilderLayoutSection",
  mixins: [LayoutSectionMixin],
  components: {
    SkillLessonCatalog,
    "layout-component": SkillLessonBuilderLayoutComponent,
    questionTemplates
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      return relatedElement && relatedElement.type === draggedElement.type;
      
    },
    onChange({ added, moved }) {
      if (added) this.createComponent(added);
      if (moved) this.moveComponent(moved);
    },
    createComponent(added) {
      let type = added.element.componentType;
      let payload = {};

      payload =
        type === "question"
          ? this.createQuestion(added)
          : this.createParagraph(added);
      this.$store.dispatch("createSkillLessonBuilderComponent", payload);
    },
    createQuestion(added) {
      let payload = {};
      // if (
      //   added.element.type === "essay" &&
      //   this.hasAssessmentQuestionOfTypeEssay
      // ) {
      //   this.$store.commit("addSnackBarNotification", {
      //     message: this.$t("multipleEssaysInAssessmentAlert"),
      //   });
      //   return;
      // }

      // if (
      //   added.element.type === "venn-diagram" &&
      //   this.hasAssessmentQuestionOfTypeVennDiagram
      // ) {
      //   this.$store.commit("addSnackBarNotification", {
      //     message: this.$t("multipleVennDiagramInAssessmentAlert"),
      //   });
      //   return;
      // }

      let newQuestion = {
        ...added.element,
        order: added.newIndex + 1,
        question: "",
        audio: {},
        imageId: null,
        teks: [],
        primarySourceAudio : new Factory.CreateAudio(),
        instructionAudio : new Factory.CreateAudio()
      };

      if(newQuestion.type == 'venn-diagram') {
        newQuestion.primarySetContent = new Factory.CreateTextContent();
        newQuestion.primarySetImage = { id: null };
        newQuestion.secondarySetContent = new Factory.CreateTextContent();
        newQuestion.secondarySetImage = { id: null };
        newQuestion.vennDiagramElements = [];
      }

      if (newQuestion.type == "multiple-choice") {
        newQuestion.choices = [];
      }

      if(newQuestion.type == 'essay' || newQuestion.type == 'written')
        newQuestion.suggestedAnswer =  new Factory.CreateTextContent()

      payload.question = newQuestion;
      payload.order = newQuestion.order;
      payload.lessonId = parseInt(this.$route.params.lessonId);
      payload.id= 0;
      return payload;
    },
    createParagraph(added) {
      let payload = {};
      let paragraph = new Factory.CreateParagraph(
        added.newIndex + 1,
        added.element
      );
      paragraph.lessonId = parseInt(this.$route.params.lessonId);
      payload.lessonId = parseInt(this.$route.params.lessonId);
      payload.order = paragraph.order;
      payload.paragraph = paragraph;
      return payload;
    },
    moveComponent(moved) {
      let component = this.components.find((e) => e.id === moved.element.id);

      this.$store.dispatch("reoderLessonComponent", component);
    },
    // getComponentPartialOrder(questionType, overallOrder) {
    //   let firstComponent = this.components.find(
    //     (e) => e.question && e.question.type == questionType
    //   );

    //   return firstComponent && firstComponent.order < overallOrder
    //     ? overallOrder - firstComponent.order + 1
    //     : 1;
    // },
  },
  computed: {
    components: {
      get() {
        return this.$store.state.LessonBuilderComponents;
      },
      set(value) {
        let components = value.filter((e) => e.id);
        this.$store.commit("updateLessonComponentsOrder", components);
      },
    },
    hasAssessmentQuestionOfTypeEssay() {
      return this.components.find((e) => e.question && e.question.type === "essay") ? true : false;
    },
    hasAssessmentQuestionOfTypeVennDiagram() {
      return this.components.find((e) => e.question && e.question.type === "venn-diagram")
        ? true
        : false;
    },
  }
};
</script>
<style lang="less" scoped>
.layout-section {
  flex-direction: row;
  .components-container {
    &::-webkit-scrollbar {
      display: none;
    }
    .lesson-banner-component {
      margin-left: 0;
      span {
        text-transform: uppercase;
        width: 400px;
      }
    }
  }

  & /deep/ .drag-drop-elements {
    // height: calc(100vh - 166px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.components-catalog {
  height: calc(100vh - 110px);
}
</style>