var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-section flex-container" },
    [
      _c(
        "div",
        { staticClass: "components-container" },
        [
          _c("div", { staticClass: "lesson-banner-component flex-container" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("skillLessonBanner")))])
          ]),
          _c(
            "draggable",
            {
              staticClass: "draggable-section",
              attrs: { options: _vm.dragOptions, move: _vm.onMove },
              on: { change: _vm.onChange },
              model: {
                value: _vm.components,
                callback: function($$v) {
                  _vm.components = $$v
                },
                expression: "components"
              }
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "flip-list", tag: "ul" } },
                _vm._l(_vm.components, function(component, index) {
                  return _c(
                    "li",
                    { key: component.id },
                    [
                      _c("layout-component", {
                        attrs: {
                          component: component,
                          componentCount: index + 1
                        }
                      })
                    ],
                    1
                  )
                })
              )
            ],
            1
          )
        ],
        1
      ),
      _c("skill-lesson-catalog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }